@import "../../styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    flex-grow: 1;

    img {
        display: block;
        width: 300px;
        height: 300px;
        margin-bottom: 48px;
    }

    p {
        margin: 0;
        max-width: 343px;
        color: $color-dark-text;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
    }
}

.title {
    margin: 0;
    margin-bottom: 24px;
    color: $color-dark-text;
        font-size: 46px;
        font-weight: 700;
        line-height: 29.5px;
        letter-spacing: -1.5px;
}