@import "../../styles/variables";

.login-view {
  margin-top: 50px;

  .message {
    color: $color-grey;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
}
