@import "../../../styles/variables";

.popup-wrapper {
    width: 0;
    height: 0;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    padding-top: 60px;
    z-index: 50;
    border-radius: 3px;
    // transition: all 0.5s ease-in-out;

    &.open {
        width: 100%;
        height: 100%;
        overflow: visible;
    }
}

.popup-inner {
    background-color: $color-white;
    border-radius: 8px;
    display: inline-block;
    width: max-content;
    position: relative;
    width: 0;
    height: 0;
    padding: 20px;
    // transition: all 1s ease-in-out;
    opacity: 0;
    
    &.open {
        width: auto;
        height: auto;
        opacity: 1;
    }
}