@import "../../styles/variables";

.wrapper {
    padding: 16px;
    flex-grow: 1;
}

.title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.5px;
    color: $color-dark-text;
}