@import "../../styles/variables";

.box {
    margin: 0px auto;
    width: 526px;
    height: 100%;
    position: absolute;

    a {
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        stroke: $color-dark-blue;

        :hover {
            stroke: $color-main-blue;
        }
    }

    .agg-header-group {
        position: sticky;
    }

    .agg-header-cell {
        padding-left: 9px;
        padding-right: 5px;
        background-color: $color-table-header;
        border: none;
        cursor: pointer;
        border: none;

        span {
            color: $color-dark-text;
        }

        &:hover,
        &:focus {
            background-color: $color-table-header !important;
        }

        &>div:last-child>div>div {
            justify-content: center;
        }
    }

    .agg-row-cell {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        margin-top: 10px;
        border-top: 4px solid $color-white;
        border-bottom: 4px solid $color-white;
        background-color: $color-table-row;

        span {
            font-size: 13px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }

        &>div {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 13px;
            text-overflow: ellipsis;
            padding: 8px 10px;
            line-height: 20px;
            word-break: break-word;
            display: flex;
            align-items: center;
            justify-content: center;

            &>div {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}