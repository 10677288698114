@import "../../styles/variables";

.pos-version {
    .select-box {
        height: 42px;
        margin-bottom: 16px;
    }

    .submit {
        display: flex;
        width: 223px;
        height: 40px;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        background: $color-dark-text;
        box-shadow: 0px 9px 18px 0px rgba(69, 74, 84, 0.40);

        span {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.56px;
            color: $color-white;
        }
    }
}