@import "../../styles/variables";

.basic-textarea {
  position: relative;

  width: 100%;
  overflow: hidden;

  border-radius: 8px;

  .custom-resizer-container {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 18px;
    height: 18px;

    background-color: $color-white;
    border-right: 1px solid $color-table-active-row;
    border-bottom: 1px solid $color-table-active-row;

    border-bottom-right-radius:8px;

    pointer-events: none;

    .icon-wrapper {
      position: absolute;
      right: 2px;
      bottom: -1px;
    }
  }

  .input-label {
    font-size: 12px;
    line-height: 20px;
  }

  textarea {
    display: flex;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    outline: none;

    background-color: $color-white;
    border: 1px solid $color-table-active-row;
    border-radius: 8px;

    resize: vertical;

    &::placeholder {
      color: $color-input;
      font-size: 14px;
    }

    &:focus {
      border-color: $color-input;

      & ~ .custom-resizer-container {
        border-color:$color-input;
      }
    }

    &:disabled {
      border-color: $color-table-active-row;
    }

    &.error {
      border-color: $color-orange;

      & ~ .custom-resizer-container {
        border-color: $color-orange;
      }
    }

    &::-webkit-resizer {
      display: none;
    }
  }
}
