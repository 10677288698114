@import "../../styles/variables";

.call-types {
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 20px;
    width: 263px;

    h2 {
        margin: 0 0 24px 20px;
        color: $color-dark-text;
        font-size: 24px;
        font-weight: 700;
        line-height: 29.5px;
        letter-spacing: -1.5px;
    }
}

.lists-wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.call-types-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: auto;

    .call-type-item {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px 12px 20px;
        transition: .4s;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;

        .delete-button {
            cursor: pointer;
            background-color: transparent;
            border: none;

            svg {
                stroke: $color-dark-blue;
                transition: stroke .4s;
            }
        }

        &.noactive {
            background-color: transparent;
            color: $color-light-blue;
        }

        &.active {
            background-color: $color-main-blue;
            color: $color-white;

            .delete-button {
                //

                svg {
                    stroke: white;
                }
            }
        }
    }

}
.new-call-type {
    padding-top: 20px;
}