@import "../../styles/variables";

.date-picker {
  width: 100%;

  .input {

    display: flex;
    flex-direction: column;

    label {
      display: block;

      .icon {
        position: absolute;
        top: 12px;
        left: 16px;

        color: $color-dark-blue

        svg {
          font-size: 16px;
        }
      }

      .input-label {
        font-size: 12px;
        line-height: 20px;
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 16px;
        padding-left: 46px;

        font-weight: normal;
        font-size: 14px;

        background-color: $color-white;

        border: 1px solid $color-border;
        border-radius: 8px;

        box-sizing: border-box;

        outline: none;

        &::placeholder {
          color: $color-input;
          font-size: 14px;
        }

        &:focus {
          border-color: $color-input;
        }

        &:disabled {
          border-color: $color-border !important;
        }

        &.error {
          border-color: $color-orange;
        }
      }
    }
  }

  .calendar {
    padding: 10px;

    border-color: $color-input;
    border-radius: 10px;

    .header {
      padding-bottom: 20px;

      background-color: $color-white;
      border-bottom: 1px solid rgb(39 41 55 / 12%);

      user-select: none;

      .month-control-icon {
        position: absolute;
        background-color: transparent;

        svg {
          fill: $color-dark-2;
        }
        border : none;

        color: $color-dark-2;

        cursor: pointer;

        &-left {
          left: 0;

          transform: rotate(270deg);
        }

        &-right {
          right: 0;
          transform: rotate(90deg);
        }
      }

      .current-date {
        color: $color-dark-2;
        font-weight: 700;
        font-size: 16px;
      }
    }

    .day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin: 0;
    }
  }
}
