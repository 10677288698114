$color-white: #ffffff;
$color-main-blue: #4EB0C2;
$color-dark-blue: #4A7A8A;
$color-light-blue: #99BCC2;
$color-table-header:#EAF3F4;
$color-table-active-row: #D3ECEF;
$color-table-row: #F6FAFD;
$color-dark-text: #454A54;
$color-shadow-box:rgba(69, 74, 84, 0.60);
$color-border: #D9DDE2;
$color-input: #ADB4BD;
$color-disabled: #EFF2F5;
$bg-main: #F6FAFD;
$color-grey: #ADB4BD;
$color-grey-2: #D3ECEF;
$color-orange: #DF715F;
$color-dark-2: #272937;
$color-hover: #2E3138;