body {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: "Outfit";
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4EB0C2 #D3ECEF;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #D3ECEF;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4EB0C2;

  border-radius: 20px;
  border: 2px solid #D3ECEF;
}

*,
input {
    font-family: "Arial";
    color: #1A1A1A;
}

input[type="checkbox"] {
    display: none;
}

svg {
    display: block;
}

button.gridjs-sort {
    position: absolute;
    right: 5px;
    top: 12px
}

div.project_director {
    cursor: pointer;
    display: inline-block;
    margin-right: 5px;
    margin-left: 0px;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
}

.ag-root-wrapper {
    border: none;
    border-radius: 8px;
}
.ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-alpine-active-color: #D3ECEF;
    --ag-selected-row-background-color: #D3ECEF;
    --ag-row-hover-color: #EAF3F4;
}

.ag-header {
    border: none;
}

