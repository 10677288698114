@import "../../../styles/variables";

.button {
    padding: 12px 32px;
    border-radius: 8px;
    background-color: $color-white;
    border: 1px solid $color-border;
    background: $color-white;
    height: min-content;
    bottom: 0px;
    text-align: center;
    color: $color-light-blue;
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 700;
        color: $color-light-blue;
    }
}