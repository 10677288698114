@import "../../styles/variables";

.box {
    .nice-select {
        width: 100%;
        background-color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-border;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        min-height: 42px;
        line-height: 40px;
        outline: 0;
        padding-left: 18px;
        padding-right: 30px;
        position: relative;
        text-align: left !important;
        transition: all .2s ease-in-out;
        user-select: none;
        white-space: nowrap;

        &:hover {
            border-color: $color-input;
        }

        &:active,
        &:focus {
            border-color: $color-input;
        }

        &:after {
            border-bottom: 2px solid $color-dark-blue;
            border-right: 2px solid $color-dark-blue;
            content: '';
            display: block;
            height: 5px;
            margin-top: -4px;
            pointer-events: none;
            position: absolute;
            right: 12px;
            top: 50%;
            transform-origin: 66% 66%;
            transform: rotate(45deg);
            transition: all .15s ease-in-out;
            width: 5px;
        }

        &.open {
            border-color: $color-input;
            border-radius: 8px 8px 0px 0px;

            &:after {
                transform: rotate(-135deg);
            }
        }

        &.disabled {
            background-color: $color-disabled;
        }
    }

    .list {
        max-height: 300px;
        overflow: auto;
        width: calc(100% + 2px);
        background-color: $color-white;
        border: 1px solid $color-input;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: -1px;
        transform-origin: 50% 0;
        transform: scale(.75) translateY(-21px);
        transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
        z-index: 9;

        &.open {
            opacity: 1;
            pointer-events: auto;
            transform: scale(1) translateY(0);
            overflow-y: auto;
            border-radius: 0 0 8px 8px;
        }
    }

    .option {
        cursor: pointer;
        font-weight: 400;
        line-height: 40px;
        list-style: none;
        min-height: 40px;
        outline: 0;
        padding-left: 18px;
        padding-right: 29px;
        text-align: left;
        transition: all .2s;

        &:hover {
            background-color: $color-table-row;
        }
    }

    .reset {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        z-index: 50;
        stroke: $color-dark-blue;
    }
}

.chip-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 8px 0;
}

.chip {
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: $color-main-blue;
    color: $color-white;
    font-size: 8px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    padding: 2px 4px;

    button {
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
    }

    svg {
        stroke: $color-white;
    }
}
