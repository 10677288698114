@import "../../../styles/variables";

.popup-delete-custom-call-type {
    width: max-content;
    position: relative;
    z-index: 5;
    background-color: $color-white;
    border-radius: 8px 0 8px 8px;

    h4 {
      margin: 0 0 16px;
      text-align: left;
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    .close {
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }

    .question {
      margin-bottom: 20px;

        .call-type-name {
            color: $color-main-blue;
            font-weight: 600;
        }
    }

    .button-wrapper {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
