@import "../../styles/variables";

.topbar {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;

    .topbar-box {
        width: 100%;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: space-between;
    }
}

.quick-filters {
    display: flex;
    align-items: center;
    gap: 24px;

    h4 {
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
    }

    .daystoopening {
        display: flex;
        gap: 16px;
        align-items: center;

        input {
            padding: 10px 11px;
            text-align: center;
            width: 60px;
            border-radius: 8px;
            border: 1px solid $color-border;
            background: $color-white;
            font-size: 14px;
            letter-spacing: -0.56px;
            height: 40px;
            box-sizing: border-box;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:disabled {
                background-color: $color-disabled;
            }
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        button {
            padding: 0;
            border: none;
            background: transparent;
            cursor: pointer;

            &:disabled {
                svg {
                    stroke: $color-border;
                    color: $color-border;
                }
            }
        }

        svg {
            stroke: $color-dark-blue;
            color: $color-dark-blue;
        }

        .daystoopening-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .days-wrapper {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
                color: $color-light-blue;
                font-size: 14px;
                letter-spacing: -0.56px;
            }
        }

        .buttons-wrapper {
            display: flex;
            gap: 12px;
        }

        .apply-days {
            fill: $color-dark-blue;

            &:disabled {
                svg {
                    fill: $color-border;
                }
            }
        }
    }

    .include-sites {
        display: inline-block;

        input {
            position: relative;
            top: 4px;
        }
    }

    .oasis_filter_heading {
        margin-left: 10px;
    }
}

.oracle_filter {
    width: calc(100% - 40px);
    display: inline-block;
    padding: 20px;
    padding-bottom: 0px;

    h4 {
        color: $color-white;
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        display: inline-block;
    }
}

.buttons-wrapper {
    display: flex;
    gap: 16px;
    margin-left: auto;
}

.select-box {
    width: 160px;
    position: relative;
}

.select-box-multi {
    width: 200px;
}

.uat-wrapper {
    display: flex;
    gap: 24px;
}