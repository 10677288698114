@import "../../styles/variables";

.open-call-note-history {
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;

    svg {
        position: relative;
        fill: $color-dark-blue
    }
}