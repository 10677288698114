@import "../../styles/variables";

.slide-checkbox {
  display: flex;
  align-items: center;

  label {
    display: flex;
    width: fit-content;
    margin: 0;

    cursor: pointer;

    .label-text {
      display: flex;
      align-items: center;

      user-select: none;
    }

    .checkbox-wrapper {
      position: relative;

      width: 40px;
      height: 22px;
      margin-left: 8px;

      background-color: $color-white;

      border: 1px solid $color-input;
      border-radius: 11px;
      box-sizing: border-box;

      .circle {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 18px;
        height: 18px;

        background-color: $color-input;
        border-radius: 50%;

        transition: background-color 0.15s ease-in-out, left 0.15s ease-in-out;
      }
    }

    input[type="checkbox"] {
      //

      &:checked {
        //

        &~.checkbox-wrapper .circle {
          left: calc(100% - 19px);

          background-color: $color-dark-text;
        }
      }

      &:disabled {
        //

        &~.checkbox-wrapper .circle {
          background-color: $color-input;
        }
      }
    }

    &.disabled {
      cursor: default;
    }
  }
}