.react-datepicker__header {
    padding: 0 !important;

    background-color: transparent !important;
    border-bottom: none !important;
}

.react-datepicker__day-names {
    margin-top: 10px;
}

.react-datepicker__day {
    &-name {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin: 0;
    }

    &--outside-month {
        color: rgb(39 41 55 / 35%);
    }
}

.react-datepicker-popper {
    z-index: 20;
}