@import "../../styles/variables";

.sidebar-wrapper {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.5s ease-in-out;

    h3 {
        margin: 0;
        color: $color-dark-text;
        font-size: 24px;
        line-height: 29.5px;
    }

    h4 {
        margin: 0;
        color: $color-dark-text;
        font-size: 18px;
        line-height: 20px;
    }

    .collapse-open {
        max-height: 1000px;
        overflow: visible;
    }

    .sidebar-inner {
        border-radius: 10px 0px 0px 10px;
        box-shadow: -2px 2px 36px 0px rgba(6, 31, 51, 0.12);
        background-color: $color-white;
        display: inline-block;
        position: absolute;
        z-index: 20;
        width: 0px;
        height: 100vh;
        right: 0;
        padding: 16px;
        transition: all 0.5s ease-in-out;
        transform: translateX(100%);
        visibility: hidden;
    }

    & div.open {
        transform: translateX(0);
        width: 526px;
        visibility: visible;

        & .close {
            opacity: 1;
        }
    }
}


.close {
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0;
    stroke: $color-dark-blue;

    & svg {
        display: block;
    }
}

.content-wrapper {
    display: grid;
    gap: 16px;
}

.header {
    display: flex;
    align-items: center;
    gap: 16px;

    .arrow-wrapper {
        display: grid;
        gap: 8px;
    }

    button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: transparent;
    }

    .arrow-up {
        transform: rotate(180deg);
    }

    .title {
        max-width: 200px;
    }
}

.new-notes-wrapper {
    display: grid;
    gap: 16px;
    transition: all 0.5s ease-in-out;
    max-height: 24px;
    overflow: hidden;

    .new-notes-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
    }

    & .open {
        transform: rotate(0deg);
    }

    textarea {
        max-width: 328px;
        border-radius: 4px;
        border: 0.5px solid $color-border;
        font-size: 14px;
        line-height: 20px;
        padding: 16px 12px;
        outline: none;

        &:placeholder {
            color: $color-input;
        }
    }
}

.pos-wrapper {
    display: grid;
    gap: 16px;
    transition: all 0.5s ease-in-out;
    max-height: 24px;
    overflow: hidden;

    .pos-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
    }

    & .open {
        transform: rotate(0deg);
    }
}

.notes-wrapper {
    display: grid;
    gap: 16px;
    transition: all 0.5s ease-in-out;
    max-height: 24px;
    overflow: hidden;

    .notes-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
    }

    & .open {
        transform: rotate(0deg);
    }

    ul {
        list-style: disc;
        margin: 0;
        padding-left: 20px;
    }

    .last-notes-box {
        display: grid;
        gap: 14px;
    }
}