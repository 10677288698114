@import "../../styles/variables.scss";


.wrapper {
  width: 100%;

  input {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    display: block;
    min-width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    border-color: $color-border;
    outline: none;
    width: 100%;
    box-sizing: border-box;


    &:focus {
      border-color: $color-input;
      color: $color-dark-2;
    }

    &::placeholder {
      font-size: 14px;
      color: $color-input;
    }
  }


  label {
    min-height: 13px;
    font-size: .75em;
    margin-top: 11px;
    margin-left: 2px;
    display: block;
    word-break: break-word;
  }
}