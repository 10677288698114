@import "../../../styles/variables";

.popup-discard-notes {
    width: max-content;
    position: relative;
    z-index: 5;
    background-color: $color-white;
    border-radius: 8px 0 8px 8px;

    h4 {
        margin: 0 0 16px;
        text-align: left;
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    .close {
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }

    .question {
        margin-bottom: 20px;
    }

    .button-wrapper {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm-discard-notes-button {
            margin: 0 auto;
            display: flex;
            width: 100%;
            height: 40px;
            padding: 12px 32px;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            background: $color-dark-text;
            box-shadow: 0 9px 18px 0 rgba(69, 74, 84, 0.40);

            span {
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: -0.56px;
                color: $color-white;
            }
        }
    }
}