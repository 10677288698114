@import "../../../styles/variables";

.save_callnotes {
    padding: 12px 32px;
    border-radius: 8px;
    background-color: $color-dark-text;
    box-shadow: 0px 14px 32px 0px $color-shadow-box;
    height: min-content;
    bottom: 0px;
    text-align: center;
    color: $color-white;
    font-size: 16px;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 700;
        color: $color-white;
    }
}