.wrapper {
    width: 100%;



    .valueContainer {
        padding: 2px 6px;
    }
    // .error {
    //     margin-top: 4px;
    //     font-size: 0.75rem;
    //     color: color($var-color-app-orange);
    // }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: scale(.25) translateY(-21px);
        }

        100% {
            opacity: 1;
            transform: scale(1) translateY(0);
        }
    }

    .menu {
        animation: fadeIn .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
    }
}
