@import "../../../styles/variables";

.add-task {
    width: max-content;
    min-width: 300px;
    position: relative;
    z-index: 5;
    background-color: $color-white;
    border-radius: 8px 0px 8px 8px;

    h4 {
        margin: 0;
        margin-bottom: 16px;
        text-align: left;
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    .close {
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }

    .save-task {
        height: 40px;
        box-sizing: border-box;
        flex-shrink: 0;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: $color-dark-text;
        box-shadow: 0px 9px 18px 0px rgba(69, 74, 84, 0.40);
        text-align: center;
        color: $color-white;
        font-size: 16px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        span {
            color: $color-white;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.56px;
        }

        &:not(:disabled) {
            &:hover {
                background-color: $color-hover;
            }
        }
    }


    .row{
        margin: 15px 0;
        text-align: left;
        &>label {
            margin-bottom: 8px;
            font-size: 14px;
        }


        .error {
            color: $color-orange;
            font-size: 14px;
        }
    }

    .textarea {
        label>*{
            font-size: 14px;
        }
    }

    .toggle {
        font-size: 14px;
    }
}