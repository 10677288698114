@import "../../../styles/variables";

.column-selector {
    cursor: default;
    position: absolute;
    top: 65%;
    right: 50%;
    width: max-content;
    z-index: 11;
    background-color: $color-white;
    padding: 20px;
    border-radius: 8px 0 8px 8px;
    box-shadow: 8px 4px 39px 0 rgba(0, 0, 0, 0.06);

    h4 {
        margin: 0 0 24px;
        text-align: left;
        color: $color-dark-text;
    }

    .close {
        padding: 0;
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }
}

.checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 500px;
    margin-bottom: 32px;
}

.buttons-wrapper {
    display: flex;
    gap: 16px;

    & button {
        cursor: pointer;
        gap: 10px;
        border-radius: 8px;
        border: none;
        background: $color-white;
        color: $color-main-blue;
    }


    .save-columns,
    .reset-columns {
        height: 40px;
        padding: 0 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
    }

    .save-columns {
        background-color: $color-dark-text;
        box-shadow: 0 14px 32px 0 $color-shadow-box;
        color: $color-white;
    }

    .reset-columns {
        background-color: $color-white;
        border: 1px solid $color-border;
        color: $color-light-blue;
    }
}

.column-checkbox {
    display: inline-block;
    padding: 12px 24px;
    background-color: $color-table-active-row;
    color: $color-light-blue;
    border-radius: 72px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.56px;
    vertical-align: middle;
}


.column-checkbox.checked {
    background-color: $color-main-blue;
    color: $color-white;

    span {
        color: $color-white;
    }
}

.hidden-column {
    display: none;
}
