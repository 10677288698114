@import "../../../styles/variables";

.popup-note-history {
    width: 400px;

    h3 {
        margin: 0px;
        padding: 0px;
        text-align: left;
        margin-bottom: 10px;
    }

    label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
    }

    .selection {
        width: calc(50% - 7px);
        display: inline-block;
        text-align: left;

        &:nth-child(odd) {
            float: right
        }

        select {
            width: 100%;
        }
    }

    .popup-inner {
        width: 150px;
    }
}