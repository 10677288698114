@import "../../../styles/variables";

.column-selector {
    width: max-content;
    position: relative;
    z-index: 5;
    background-color: $color-white;
    border-radius: 8px 0px 8px 8px;

    h4 {
        margin: 0;
        margin-bottom: 16px;
        text-align: left;
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    .close {
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }
}

.access-days-wrapper {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    gap: 24px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    input {
        padding: 16px;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid $color-border;
        background-color: $color-white;
        font-size: 14px;
        letter-spacing: -0.56px;

        &::placeholder {
            color: $color-input;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    button {
        height: 40px;
        box-sizing: border-box;
        flex-shrink: 0;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: $color-dark-text;
        box-shadow: 0px 9px 18px 0px rgba(69, 74, 84, 0.40);
        text-align: center;
        color: $color-white;
        font-size: 16px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $color-white;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.56px;
        }
    }
}

.checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 500px;
}

.access-wrapper {
    width: min-content;
    display: flex;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid $color-border;
    background-color: $color-white;

    &>div {
        height: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease;
        padding: 5.5px 22px;
        color: $color-input;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
    }

    &>.active {
        background-color: $color-table-header;
        color: $color-main-blue;
    }
}

.daystoopening {
    display: flex;
    gap: 16px;
    align-items: center;

    h4 {
        margin: 0;
        color: $color-dark-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    input {
        padding: 12px 11px;
        text-align: center;
        width: 32px;
        border-radius: 8px;
        border: 1px solid $color-border;
        background-color: $color-white;
        font-size: 14px;
        letter-spacing: -0.56px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .daystoopening-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .days-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            color: $color-light-blue;
            font-size: 14px;
            letter-spacing: -0.56px;
        }
    }

}

.column-checkbox {
    display: inline-block;
    padding: 8px 12px;
    background-color: $color-table-active-row;
    color: $color-light-blue;
    border-radius: 72px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.56px;
    vertical-align: middle;
}

.column-checkbox.checked {
    background-color: $color-main-blue;
    color: $color-white;

    span {
        color: $color-white;
    }
}

.hidden-column {
    display: none;
}