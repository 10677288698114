@import "../../styles/variables";

.container {
    width: 100%;
    position: fixed;
    height: 100%;
    background-color: $bg-main;
    display: flex;

    &.autoHeight {
        position: static;
    }
}