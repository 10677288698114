@import "../../../styles/variables";

.primary-button {
    padding: 8px 8px;
    border-radius: 8px;
    background-color: $color-dark-text;
    height: min-content;
    bottom: 0px;
    text-align: center;
    color: $color-white;
    font-size: 16px;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }

    span {
        font-weight: 700;
        color: $color-white!important;
        font-size: 14px;
    }
}