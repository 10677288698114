@import "../../styles/variables.scss";

.box {
    position: relative;

    input {
        width: 200px;
        background-color: $color-white;
        border-radius: 5px;
        border: 1px solid $color-border;
        box-sizing: border-box;
        clear: both;
        display: block;
        float: left;
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0;
        padding-left: 40px;
        position: relative;
        text-align: left !important;
        transition: all .2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        box-sizing: border-box;

        &:hover {
            border-color: $color-input;
        }

        &:active,
        &:focus {
            border-color: $color-input;
        }


        &.open {
            border-color: $color-input;
            border-radius: 8px 8px 0px 0px;
        }

        &::placeholder {
            color: $color-input;
        }

        &:disabled {
            background-color: $color-disabled;
        }
    }

    .list {
        max-height: 110px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        background-color: $color-white;
        border: 1px solid $color-input;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: 50% 0;
        transform: scale(.75) translateY(-21px);
        transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
        z-index: 9;

        &.open {
            opacity: 1;
            pointer-events: auto;
            transform: scale(1) translateY(0);
            overflow-y: auto;
            border-radius: 0 0 8px 8px;
        }
    }

    .option {
        cursor: pointer;
        font-weight: 400;
        line-height: 40px;
        list-style: none;
        min-height: 40px;
        outline: 0;
        padding-left: 18px;
        padding-right: 29px;
        text-align: left;
        transition: all .2s;

        &:hover {
            background-color: $color-table-row;
        }
    }

    .reset {
        position: absolute;
        top: 13px;
        right: 24px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        z-index: 10;
        stroke: $color-dark-blue;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        z-index: 5;
        stroke: $color-dark-blue;
    }
}
