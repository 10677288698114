@import "../../styles/variables";

.sidebar-wrapper {
    width: 100vw;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.5s ease-in-out;

    &.open {
        height: 84px;
    }

    .sidebar-inner {
        border-radius: 10px 0px 0px 10px;
        box-shadow: -2px 2px 36px 0px rgba(6, 31, 51, 0.12);
        background-color: $color-white;
        display: inline-block;
        position: absolute;
        z-index: 20;
        width: calc(100% - 30px);
        height: 0;
        right: 0;
        padding: 16px;
        transition: all 0.5s ease-in-out;
        transform: translateY(100%);
        visibility: hidden;
        display: flex;
        gap: 16px;
    }

    & div.open {
        transform: translateY(0);
        height: 52px;
        visibility: visible;

        & .close {
            opacity: 1;
        }
    }
}