@import "../../styles/variables";

.sidebar-wrapper {
    width: 32px;
    height: 100%;
    // transition: all 0.5s linear;
    transform: translateX(-100%);
    position: relative;
    background-color: $color-white;
    position: relative;
    z-index: 20;

    &.open {
        transform: translateX(0);
        width: 263px;
    }

    &.blocked {
        button {
            fill: $color-dark-blue;

            cursor: default;
        }
    }

    .sidebar-inner {
        width: 263px;
        border-radius: 10px 0px 0px 10px;
        height: 100%;
        padding-bottom: 20px;
        // transition: all 0.5s linear;
        overflow: hidden;
        transform: translateX(-100%);

        &.open {
            transform: translateX(0);
            overflow: visible;
        }

        &.disabled {
            background-color: $color-disabled;
            opacity: 0.5;
        }
    }
}

.title {
    width: 263px;
    padding: 12px 20px 6px 20px;
    margin: 0;
    color: transparent;
    background-color: $color-white;
    opacity: 0;
    transition: all 0.3s linear;
    transform: translateX(-100%);

    &.visible {
        color: $color-dark-text;
        background-color: transparent;
        transform: translateX(0);
    }

    &.open {
        opacity: 1;
    }
}


.close {
    position: absolute;
    top: 14px;
    right: -32px;
    padding: 7px;
    border: none;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
    background: $color-white;
    box-shadow: -4px 4px 81px 0px rgba(0, 0, 0, 0.40);
    stroke: $color-dark-blue;
    transition: transform 0.5s cubic-bezier(0.1, 0.7, 1, 0.1);
    z-index: 5;

    & svg {
        transform: rotate(-90deg);
        display: block;
    }

    &.open {
        right: 0;

        & svg {
            transform: rotate(90deg);
        }
    }
}