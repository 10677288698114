@import "../../../styles/variables";

.confirm-delete-custom-call-type-button {
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: $color-dark-text;
  box-shadow: 0 9px 18px 0 rgba(69, 74, 84, 0.40);

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.56px;
    color: $color-white;
  }
}
