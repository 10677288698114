@import "../../../styles/variables";

.column-selector-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    fill: $color-dark-blue;
    position: relative;
}
