@import "../../../styles/variables";

.popup {
    position: relative;

    h3 {
        margin: 0px;
        padding: 0px;
        text-align: center;
        margin-bottom: 24px;
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.64px;
    }

    .close {
        padding: 0;
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        stroke: $color-dark-blue;

        & svg {
            display: block;
        }
    }
}

.call-note-history-wrapper {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.call-note-history-box {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid $color-input;
    background: $color-white;

    button {
        border: none;
        background: transparent;
        cursor: pointer;
        color: $color-main-blue;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.56px;
    }
}

.call-note-history-link {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    max-width: 323px;
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.download {
    max-width: 200px;
    border: none;
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 8px;
    background: $color-dark-text;
    box-shadow: 0px 9px 18px 0px $color-shadow-box;
    color: $color-white;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.56px;

    a {
        text-decoration: none;
        color: inherit;
    }
}

.call-note-history-multibox {
    display: flex;
    gap: 12px;
}

.margin {
    margin-top: 24px;
}

.little {
    padding-top: 10px;
    padding-bottom: 10px;
}