@import "../../styles/variables";

.project-bar {
    width: 100%;
    height: 100%;
}

.project-director {
    cursor: pointer;
    width: 100%;
    height: calc(100% - 8.5rem);
}

.agg-header-group {
    position: sticky;
}

.agg-header-cell {
    padding-left: 9px;
    padding-right: 5px;
    background-color: $color-table-header;
    border: none;
    cursor: pointer;

    span {
        color: $color-dark-text;
    }

    &:hover,
    &:focus {
        background-color: $color-table-header !important;
    }

    &>div:last-child>div>div {
        justify-content: center;
    }
}

#project-list {
    padding-right: 3px;
}

.agg-row-cell {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 4px solid $bg-main;
    border-top: 4px solid $bg-main;

    span {
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
    }

    &>div {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 13px;
        text-overflow: ellipsis;
        padding: 8px 10px;
        line-height: 20px;
        word-break: break-word;
        display: flex;
        align-items: center;

        &>div {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.total {
    padding:1rem 0;
    font-size: 0.875rem;
}