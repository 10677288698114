@import "../../../styles/variables";

.ghost-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 1rem;
  cursor: pointer;
  text-align: center;

  color: $color-grey;
  font-weight: 700;

  font-size: 1rem;

  background-color: #fff;

  border: 1px solid  $color-grey-2;
  border-radius: 8px;
  &:hover {
    color: $color-main-blue;
  }
}
